.accreditations{
	background:#f4f4f4;
	padding:43px 0;
	text-align:center;
	.single-accreditation{
		display:inline-block;
		margin: 0 20px;
		img{
			filter:grayscale(100%);
		}
	}
}

.newsletter{
	background:$dark-red;
	padding:55px 0;
	flex:1;
	.wrapper{
		display:flex;
		justify-content:space-between;
	}
	form{
		.button{
			display:inline-block;
			height:90px;
			border:0;
			width:260px;
		}
		input{
			color:#cac9c9;
			font-size:20px;
			height:90px;
			border-radius:45px;
			padding:35px 50px;
			font-family:$main-font;
			outline:0;
			font-weight:700;
			margin-right:15px;
			width:440px;
		}
	}
}

.social{
	list-style:none;
	margin:0;
	padding:0;
	display:flex;
	align-items:center;
	flex:1;
	justify-content:flex-end;
	li{
		display:inline-block;
		padding-left:20px;
		a svg circle{
			transition:all 300ms ease;
		}
		a:hover svg circle{
			fill:$green !important;
		}
	}
	svg{
		height:64px;
		width:64px;
	}
}

footer{
	background: $main-color;
	padding:110px 0 70px 0;
	color:#fff;
	.column{
		width:25%;
		float:left;
		padding-right:60px;
	}
	h3{
	font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 25px 0;
	}
	p{
		font-size:18px;
		margin:0 0 15px 0;
	}

	ul{
		list-style:none;
		margin: 0 0 0 15px;
		padding:0;
		font-size:18px;
		li{
			margin-bottom:10px;
			position:relative;
			&:before{
				content:'';
				position:absolute;
				height:7px;
				width:7px;
				background:$green;
				border-radius:50%;
				left:-18px;
				top:0; bottom:0;
				margin:auto;
				z-index: 2;
			}
		}
	}
}

.about-column{
	p{
		line-height: 24px;
		margin:0 0 10px 0;
		text-indent:50px;
	}
	a{
		color:$green;
		font-size:18px;
		&:hover{
			color:darken($green, 10%);
		}
	}
}

.site-info{
	font-size:17px;
	text-align:center;
	margin-top:60px;
	p{
		margin:0;
	}
	a{
		color:#fff;
	}
}


.two-column{
	column-count:2;
	column-gap: 40px;
}