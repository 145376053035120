
// FONTS
$main-font: 'Lato', sans-serif;
// $sub-font: Helvetica, sans-serif;


// COLORS
$main-color: #c11e1e;
$dark-red:#a11b1b;
$grey: #cccccc;
$light-grey: #ebebeb;
$green: #92bd3e;

$font-color: #4b4b4b;
$dark-font: #4d4d4d;
$grey-font: #ababab;
$border-color: #e5e5e5;


// LINKS
$link-color: #fff;
$link-hover-color: darken($link-color, 20%);
$link-active-color: #1ea584;


// BUTTONS
$button-color: #c11e1e;
$button-hover: darken($button-color, 15%);


//  OTHER
$wrapper-width: 1450px;
$sidebar-width: 350px;
$border-radius: 50px;
$animation: all 250ms ease;