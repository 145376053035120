@media (max-width: 1450px) {
	
	.wrapper {
		width: 100%;
		padding: 0 40px;
	}

	.menu-section {
		width: 100%;
	}

		.top-bar {
			padding: 20px 25px;
		}

		.main-navigation {
			padding: 35px 40px 35px 0;
		}

		.main-slider .wrapper {
			padding: 0 100px;
		}

		.sectors {
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 60px;
		}

		.home-intro {
		    margin-bottom: 130px;
		}

		.single-sector {
			padding: 0 30px;
			margin-bottom: 50px;
			width: calc(100% / 3);
			flex-direction: column;
		}

	.single-service {
		width: 100%;
	}

	.accreditations .single-accreditation {
		margin: 20px;
	}

	footer .column p:last-child strong {
	    display: block;
	}

	.project-content {
		max-width: 1200px;
		width: 100%;
	}

	.service-page {
		.left {
			width: 55%;
		}
		.right {
			width: calc(45% - 20px);
			margin-left: 20px;
		}
	}

.subpage-header-bg {
    height: 215px;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
}

}

@media (max-width: 1200px) {

	.top-bar {
		flex-wrap: wrap;
		justify-content: center;
		p {
			width: 100%;
			text-align: center;
			span {
				margin: 0 10px;
			}
		}
		p:last-child {
			margin-top: 10px;
		}
	}

	.main-navigation{
		.menu-primary-container{
			display: none;
		}
		a.button {
			margin-left: 0;
		}
	}

	.menu-button {
		position: relative;
		background:$main-color;
	    height: 80px;
	    border-radius: 25px;
	    margin-left: 20px;
	    width: 80px;
	    cursor: pointer;
		> span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 100%;
			height: 100%;
			> span {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background: #fff;
				width: 60%;
				height: 3px;
				border-radius: 10px;
				transition: background 0.4s ease;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: -10px;
					height: 3px;
					width: 80%;
					background: #fff;
					border-radius: 10px;
					transition: all 0.4s ease;
				}
				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 10px;
					height: 3px;
					width: 90%;
					background: #fff;
					border-radius: 10px;
					transition: all 0.4s ease;
				}
			}
		}
		&:hover > span > span:before, &:hover > span > span:after {
			width: 100%;
		}
	}

	.menu-button.active {
		> span > span {
			background: transparent;
			&:before {
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%) rotate(45deg);
			}
			&:after {
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%) rotate(-45deg);
			}
		}
	}

	#page.active {
		transform: translateX(calc(-100% + 140px));
	}

	.mobile-menu {
		display: block;
		position: fixed;
		top: 0;
		right: 0;
		width: calc(100% - 140px);
		height: 100%;
		background: $main-color;
		transform: translateX(calc(100% + 140px));
		transition: transform 0.4s ease;
		> div {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			ul {
				padding: 0;
				margin: 0;
				list-style-type: none;
				text-align: center;
				li {
					margin: 20px 0;
					position: relative;
					&:not(:nth-last-child(-n+2)):after {
						content: '';
						position: absolute;
						bottom: -12px;
						left: 50%;
						transform: translateX(-50%);
						width: 30px;
						height: 2px;
						background: rgba(255,255,255,0.3);
					}
					a {
						font-size: 28px;
					}
				}
				.mobile-cta {
					position: relative;
					border-radius: 50px;
					padding: 15px 20px;
					display: block;
					background: #fff;
					text-align: center;
					text-transform: uppercase;
					transition: all 250ms ease;
					cursor: pointer;
					outline: 0;
					a {
						color: $main-color;
					}
					&:before {
						color: $main-color;
					    font-weight: 700;
					    font-size: 24px;
					    margin-right: 10px;
					}
				}
			}
		}
	}

		.mobile-menu.active {
			transform: translateX(0%);
		}

		.subpage-header-bg {
		    height: 240px;
		}

	.home-intro {
		padding: 0 60px;
		p{
			font-size:40px;
			    margin: 0 auto 40px auto;
		}
		.button {
		    width: 270px;
		    margin: 0 auto;
		    padding: 20px 0;
		    font-size: 22px;
		}
	}

	.button.large-button {
	    font-size: 22px;
	}

	.left-background {
		width: 100%;
	}

	.right-background {
	    width: calc(100% - 80px);
	    top: 500px;
	    right: 40px;
	    height: calc(100% - 540px);
	}

	.home-services .left {
		height: 500px;
		text-align: center;
		width: 100%;
		> div {
			width: 100%;
		}
		.button {
			margin: 0 auto;
		}
	}

	.home-services .right {
		width: 100%;
		padding: 80px;
		.single-service {
			padding-right: 0;
		}
	}

	.single-news {
		width: 100%;
		margin-right: 0;
		.thumb {
			width: 300px;
			float: left;
		}
		*:not(:first-child) {
		    float: left;
		    width: calc(100% - 350px);
		    clear: none;
		    text-align: left;
		    margin-left: 50px;
		}
		h3 {
			margin: 10px auto 18px auto;
		}
	}	

		.latest-news > div > div:not(:nth-last-child(-n+2)) {
		    margin-bottom: 50px;
		}

	.newsletter {
		.wrapper {
			flex-wrap: wrap;
			justify-content: center;
			form {
				width: 100%;
				text-align: center;
			}
			.social {
				margin-top: 50px;
				justify-content:center;
			}
		}
	}

	.newsletter form .button {
	    height: 80px;
	}

	.newsletter form input {
	    height: 80px;
	}

	footer .column {
		&:first-child {
			width: 100%;
			text-align: center;
			margin-bottom: 50px;
			padding-right: 0;
		}
		&:nth-child(2), &:nth-child(3) {
			width: 50%;
			padding-right: 0;
			.two-column {
				column-count: 1;
			}
		}
		&:nth-child(4) {
			margin-top: 50px;
			width: 100%;
			padding-right: 0;
		}
	}

	.project {
		width: calc((100% / 3) - 3%);
	}

	.services-archive {
		.third {
			width: 50%;
		}
	}

	.service-page {
		.left {
			ul {
				margin: 0;
			}
		}
	}

	.left-page {
		width: 100%;
	}

		.contact-page .left-page {
			padding: 0;
		}

	.sidebar {
		margin-top: 50px;
		width: 100%;
	}

	.right-sidebar {
		padding-left: 0;
	}

	.page-template-news {
		.right-sidebar {
			display: none;
		}
	}

	.single-post {
		.right-sidebar {
			display: none;
		}
	}

	.contact-page .right-sidebar .content {
	    display: flex;
	    p {
	    	width: calc(100% / 3);
	    	margin: 0;
	    }
	}

	.row-1 .left-content, .row-3 .left-content {
		width: 60%;
	}

	.row-1 .right-image, .row-3 .right-image {
		width: 40%;
	}

	.row-2 .left-image {
		width: 40%;
	}

	.row-2 .right-content {
		width: 60%;
	}

	.single-news-post .left-page {
	    padding-right: 0;
	}

	.project-filter{
		margin: 0 0 50px 0;
		li{
			margin-bottom: 16px;
		}
	}

}

@media (max-width: 900px) {

	.main-navigation a.button {
		display: none;
	}

	.main-slider .slide .content {
		text-align: center;
		.button {
		    margin: 30px auto 0 auto;
		}
	}

	.single-sector {
		width: 100%;
		text-align: left;
		padding-left: 222px;
		position: relative;
		min-height: 170px;
		h2 {
			margin: 10px 0 12px;
		}
		.icon {
			position: absolute;
			top: 0;
			left: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	.single-news *:last-child {
		float: right;
	}

	.home-intro {
	    margin-bottom: 90px;
	}

	.home-services {
	    background: url(img/service_bg.jpg) center center/cover;
	    position:relative;
	    margin-bottom:80px;
	    &:after{
    	    content: '';
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    background: rgba(255, 0, 0, 0.7);
	    }
	    .right{
	    	background:#ebebeb;
	    	margin-bottom: 40px;
	    }
	    .left {
		    height: auto;
		    display: block;
		    padding: 60px 0;
		}
	}


	.left-background, .right-background{
		display:none;
	}

	.newsletter {
		text-align: center;
		form input {
			width: 100%;
		}
		button {
			margin-top: 20px;
		}
	}

	footer .column {
		&:first-child {
			text-align: center;
			margin-bottom: 0;
		}
		&:nth-child(2), &:nth-child(3), &:nth-child(4) {
			display: none;
		}
	}

	.project {
		width: calc(50% - 3%);
	}

	.project-filter li {
		margin-bottom: 15px;
	}

	.project-location {
		width: 100%;
	}

	.project-content {
		.details {
			width: 100%;
		}
		.left-content {
			width: 100%;
			padding: 0;
		}
	}

	.service-page {
		flex-wrap: wrap;
		.left {
			width: 100%;
		}
		.right {
			width: 100%;
			margin: 30px 0 0;
			text-align: center;
			img {
				width: auto;
			}
		}
	}

	.history-page .row {
		flex-wrap: wrap;
		p{
			font-size:20px;
		}
	}

	.history-feature {
    margin: 65px auto 80px auto;
    p{
    	font-size:36px;
    }
    ul{
    	font-size:20px;
    }
}

.assets{
	margin-bottom: -60px;
	h3{
		font-size:22px;
	}
	p{
		font-size:19px;
	}
}

	.row-1 .left-content, .row-3 .left-content, .row-2 .right-content {
		width: 100%;
	    padding: 0 0 30px;
	    order: 1;
	}

	.row-1 .right-image, .row-3 .right-image, .row-2 .left-image {
		width: 100%;
		text-align: center;
		order: 2;
	}

		.row-1 .right-image img, .row-3 .right-image img, .row-2 .left-image img {
			max-width: 100%;
			width: auto;
		}

	.assets {
		columns: 1;
	}

	.news-single-archive{
		.content {
    		width: calc(100% - 280px);
    		p{
	    		font-size: 16px;
	    		margin: 10px 0;
    		}
    		.button{
    			width:160px;
    			font-size:15px;
    		}
    		h2{
    			font-size: 20px;
    			line-height: 1.3;
    		}
		}
		.thumb{
			width:280px;
		}
	}

	.contact-page form input[type="text"], .contact-page form input[type="email"], .contact-page form textarea {
	    padding: 13px 25px;
	    font-size: 16px;
	}

	.sidebar-box{
		margin-bottom:0;
		h3, p{
			font-size:17px;
		}
	}

	#map {
    height: 700px;
}

}

@media (max-width: 700px) {

	.menu-button {
	    height: 60px;
	    width: 60px;
	}

	.subpage-header-bg {
	    height: 220px;
	}

	.top-bar p {
	    font-size: 14px;
	}


	.main-slider {
		margin-top: 208px;
		.slide {
			padding-top: 0;
			height:600px;
			h2{
				font-size:32px;
			}
			p{
				font-size:19px;
				font-weight:500;
			}
			.wrapper {
				padding: 0 40px;
				height: 100%;
				width: calc(100% - 80px);
				.content {
				    width: calc(100% - 80px);
				    position: absolute;
				    top: 50%;
				    transform: translateY(-50%);
				}
			}
		}
	}

	.home-intro {
	    padding: 0;
	    p{
	    	font-size:34px;
	    }
	}

	.button.large-button {
	    font-size: 20px;
	}

	.home-intro .button {
	    padding: 15px 0;
	    font-size: 20px;
	}

	.home-main {
	    padding: 60px 0;
	}

	.home-services .right {
    width: 100%;
    padding: 40px;
}

	.home-services .right {
	    padding: 40px;
	    h3{
	    	font-size:26px;
	    }
	}

	.home-services .right .single-service:last-of-type{
		margin-bottom:0;
	}

	.home-services .left p {
    font-size: 18px;
}

	.latest-news > div > div:not(:nth-last-child(-n+2)) {
	    margin-bottom: 60px;
	}

	.section-title, .subpage-title h1{
		font-size:48px;
		line-height: 1.2;
	}

	.view-all-news {
	    margin-bottom: 50px;
	}

	.newsletter form input{
		font-size:18px;
	}

	.social svg {
	    height: 55px;
	}

	.single-sector {
	    padding-left: 205px;
	    padding-right: 0;
	    p{
	    	font-size:17px;
	    }
	}

	.single-news {
		padding: 0 40px;
		p{
			font-size:18px;
		}
		h3{
			font-size:21px;
		}
		.thumb {
			width: 100%;
			margin-bottom: 10px;
			img {
				width: 100%;
			}
		}
		*:not(:first-child) {
			width: 100%;
			margin-left: 0;
			padding: 0 20px;
		}
	}

	.project {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		min-height: initial;
		.thumbnail img {
			height: auto;
			max-height: 400px;
		}
	}

	.project-filter{
		display:none;
	}

	.filters-select{
		display:block;
		font-size:18px;
		width:100%;
		margin:0 auto 40px auto;
		-webkit-appearance: none;
	    -moz-appearance: none;
	    border: 1px solid #d0d0d0;
	    font-family: "Lato", sans-serif;
	    padding: 10px 50px 10px 15px;
	    width: 100%;
	    background: #fff url(img/down-arrow.png);
	    background-repeat: no-repeat;
	    background-position: right 15px center;
	    cursor: pointer;
	    text-align:center;
	}

	.services-archive {
		.third {
			width: 100%;
		}
	}

	.news-single-archive {
		margin-bottom: 75px;
		.thumb {
			width: 100%;
			margin-bottom: 10px;
		}
		.content {
			width: 100%;
			padding:15px 0 0 0;
		}
	}

	.contact-page .right-sidebar .content {
	    flex-wrap: wrap;
	    p {
	    	width: 100%;
	    	margin: 0 0 20px;
	    	text-align: center;
	    }
	}

	.contact-page li#field_1_1, .contact-page li#field_1_2, .contact-page li#field_1_3, .contact-page li#field_1_4 {
		width: 100%;
		padding-left: 0;
	}

	.contact-page form .button {
		    font-size: 16px;
		}

	.accreditations .single-accreditation img {
	    max-width: 200px;
	}

	footer p {
	    font-size: 16px;
	}

	.accreditations {
	    padding: 25px 0;
	}

	.site-main {
	    padding: 50px 0 50px 0;
	}

	.subpage-title {
	    margin-bottom: 50px;
	}
	.subpage-intro {
    margin: -50px auto 50px auto;
}

	.single-news-post .left-page {
	    p{
	    	font-size:16px;
	    }
	}

	.project-location {
	    font-size: 20px;
	    margin: -50px auto 65px auto;
	}

.project-content .slider .slide {
    height: 360px;
}	


}

@media (max-width: 500px) {

	.wrapper {
    padding: 0 30px;
}

	.main-navigation {
	    padding: 35px 40px 45px 0;
	}

	.subpage-header-bg {
	    height: 145px;
	}

	.menu-button {
	    height: 50px;
	    width: 50px;
	}

	.top-bar{
		display:none;
	}

	.header-main {
	    padding-top: 10px;
	}

	.main-slider {
	    margin-top: 140px;
	}

	#page.active {
	    transform: translateX(calc(-100% + 120px));
	}

	.mobile-menu {
	    width: calc(100% - 120px);
        transform: translateX(calc(100% + 120px));
        div ul li a {
			font-size: 20px;
        }
	}

	.mobile-menu > div ul .mobile-cta{
		text-transform:initial;
	}

	.mobile-menu > div ul .mobile-cta:before {
	    font-size: 20px;
	}

	.header-main h1 {
		align-self: center;
	}

	.prev-slick {
		left: 10px;
	}

	.next-slick {
		right: 10px;
	}

	.slick-slider .slide .wrapper {
		padding: 0 30px;
		width: 100%;
		.content {
		    width: calc(100% - 60px);
		}
	}

	.main-slider {
		.slide {
			height:450px;
			h2 {
				font-size: 25px;
			}
			p {
				font-size: 17px;
			}
		}
	}

	.main-slider .slide .content .button {
	    margin: 20px auto 0 auto;
	    font-size: 16px;
	    padding: 13px;
	}

	.home-intro {
		padding: 0;
		p {
			font-size: 35px;
		}
	}

	.single-sector {
	    padding: 0;
	    text-align: center;
	    background:#f4f4f4;
	    padding: 25px;
    	border-radius: 10px;
	    .icon {
	    	position:static;
	    	height:auto;
	    	width:auto;
	    	font-size:60px;
	    }
	    p, a{
	    	font-size:16px;
	    }
	    h2{
	    	font-size:23px;
	    }
	}

	.home-services .left h2 {
	    font-size: 31px;
	}

	.home-services .left p {
	    font-size: 17px;
	}

	.home-services .right {
	    padding: 30px;
	}

	.home-services .right h3 {
	    font-size: 23px;
	}

	.section-title, .subpage-title h1 {
	    font-size: 40px;
	    line-height: 1;
	}

	.subpage-intro {
	    font-size: 17px;
	    margin: -50px auto 50px auto;
	}

	.latest-news hr, .subpage-title:before{
		display:none;
	}

	.view-all-news {
	    font-size:16px;
	}

	.single-news {
		padding: 0;
	}

	.accreditations .single-accreditation img {
	    max-width: 100px;
	}

	.newsletter {
	    padding: 40px 0 30px 0;
	    form input{
	    	font-size:17px;
	    	height: auto;
    		padding: 15px 30px;
	    }
	    form .button{
	    	height:auto;
	    }
	}

	.newsletter .wrapper .social {
	    margin-top: 40px;
	    svg{
	    	width:45px;
	    }
	    li{
	    	&:first-of-type{
	    		padding-left:0;
	    	}
	    }
	}

	footer {
   		padding: 70px 0 70px 0;
	    p{
	    	font-size:15px;
	    	text-indent:0px !important;
	    }
	}

	.subpage-title h1 {
		padding: 0;
	}

	.services-archive .service{
		padding:30px;
		h2{
		    font-size: 25px;
		    line-height: 1.25;
		    margin: 0;
		}
		p{
			font-size:15px;
		}
		.button{
			max-width:100%;
		}
		.thumb{
			margin: -30px -30px 25px -30px;
		}
	}

	.subpage-title {
	    margin-bottom: 60px;
	}

	.services-archive .third {
		padding: 0;
	}

	.news-single-archive .content {
		padding: 10px 0 0 0;
	}

	.home-intro{
		margin-bottom:80px;
		p{
			font-size: 28px;
	    	margin: 0 auto 30px auto;
		}
	}

	.home-intro .button {
	    padding: 12px 0;
	    font-size: 18px;
	    width: 220px;
	}

	.button.large-button {
	    font-size: 18px;
	    max-width: 100%;
	    i{
	    	font-size:20px;
	    }
	}

	.home-services {
	    margin-bottom: 60px;
	}

	.service-page .left {
	    font-size: 17px;
	    padding: 30px;
	    p:first-of-type{
	    	margin-top:0;
	    }
	}

	.history-page .row {
		flex-wrap: wrap;
		margin-bottom: 30px;
		p{
			font-size:18px;
		}
	}

	.history-feature {
	    margin: 60px auto 60px auto;
	    p{
	    	font-size:26px;
	    }
	    ul{
	    	font-size:16px;
	    }
	}

	.assets{
		margin-bottom: -20px;
		h3{
			font-size:20px;
		}
		p{
			font-size:18px;
		}
	}

	.contact-page form textarea {
	    padding: 25px;
	    height: 200px;
	    padding: 26px 25px !important;
	}

	.contact-page form .button {
	    font-size: 16px;
	    margin: 10px auto 0 auto;
	}

	#map{
		height:500px;
	}

	.project .thumbnail img {
	    height: auto;
	    max-height: 280px;
	}

	.project h2 {
	    font-size: 22px;
	}

	.project .location {
	    font-size: 17px;
	}

	.project-content .slider .slide {
	    height: 250px;
	}

	.project-content .slider .slick-dots{
		padding:0;
	}

}


@media (max-width: 370px) {

.accreditations .single-accreditation {
    margin: 15px;
    img{
    	max-width:85px;
    }
}

.main-navigation {
    padding: 35px 15px 45px 0;
}

.mobile-menu div ul li a {
    font-size: 18px;
}

.subpage-title {
    margin-bottom: 50px;
    h1 {
	    font-size: 35px;
	}
}

.subpage-intro {
    font-size: 16px;
}

.service-page .left {
    font-size: 16px;
    padding:25px;
    ul{
    	padding-left:15px;
    	li{
    		margin-bottom:5px;
    	}
    }
}

	.history-page .row {
		flex-wrap: wrap;
		p{
			font-size:16px;
		}
	}

	.history-feature {
	    margin: 60px auto 60px auto;
	    p{
	    	font-size:24px;
	    }
	    ul{
	    	font-size:16px;
	    	li{
	    		display:block;
	    	}
	    }
	}

	.assets{
		h3{
			font-size:19px;
		}
		p{
			font-size:16px;
		}
	}

	.sidebar-box{
		h2, p{
			font-size:16px;
		}
	}

	.contact-page form input[type="text"], .contact-page form input[type="email"], .contact-page form textarea {
    font-size: 15px;
}


.project-content .slider {
    background: #eee;
    margin-bottom: 40px !important;
}

.project-content .slider .slide {
    height: 180px;
}

}