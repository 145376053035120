.site-main{
	padding:70px 0 110px 0;
}

.subpage-title{
	position:relative;
	text-align:center;
	margin-bottom:80px;
	&:before{
		content:'';
		position:absolute;
		top:50%;
		left:0;
		right:0;
		width:100%;
		height: 2px;
	    background: #d1d1d1;
	}
	h1{
		text-transform:uppercase;
		font-size: 45px;
	    font-weight: 500;
	    line-height: 1.39;
	    letter-spacing: -2px;
	    text-align: center;
	    margin: 0;
	    color: #d0d0d0;
	    background: #fff;
	    padding: 0 40px;
	    display:inline-block;
	    position: relative;
    	z-index: 1;
	}
}

.subpage-intro{
	font-size:18px;
	color:#d0d0d0;
	width:500px;
	max-width:100%;
	margin:-65px auto 80px auto;
	text-align:center;
}

//SERVICES ARCHIVE
.services-archive{
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	.third{
		width:33.333%;
		padding:0 20px;
		margin-bottom:40px;
	}

	.service{
		background:$light-grey;
		padding:35px;
		height:100%;
		h2{
			font-size:30px;
			line-height:1.25;
			margin:0;
		}
		.button{
			width:200px;
		}
		.thumb {
		    margin:-35px -35px 30px -35px;
		    border-bottom: 6px solid #c11e1e;
		    img{
		    	display:block;
		    	    width: 100%;
		    }
		}
	}
}


// News
.news-single-archive{
	margin-bottom:60px;
	&:last-of-type{
		margin-bottom:0;
	}
	.thumb{
		float:left;
		border-radius:45px;
		overflow:hidden;
		position:relative;
		width:350px;
		img{
			display:block;
		}
		&:before{
			content: '';
		    position: absolute;
		    height: 100%;
		    width: 100%;
		    bottom: 0;
		    right: 0;
		    background: transparent;
		    border-radius:45px;
		    border: 10px solid rgba(255,255,255,0.7);
		    pointer-events:none;
		}
	}
	.content{
		float:left;
		width:calc(100% - 350px);
		padding:0 35px;
		h2{
			margin:0;
		}
		.date{
			color:$main-color;
			font-weight: 600;
		}
		p{
			font-size:18px;
			margin:20px 0;
		}
		.button{
			width:200px;
		}
	}

}

// CONTACT
.contact-page{
	.left-page{
		padding-right:80px;
	}
	form{
		ul{
			list-style-type:none;
			margin:0;
			padding:0;
		}
			input[type="text"], input[type="email"], textarea{
			width:100%;
			border-radius:50px;
			padding:15px 25px;
			outline:0;
			font-family:$main-font;
			font-size:18px;
			max-width:100%;
			background: #f3f3f3;
			border:0;
			resize:none;
		}
		textarea {
			padding: 25px;
		}
		.button{
			font-size:18px;
			margin-top:10px;
			width:150px;
			text-align:center;
			font-weight:500;
		}
		.gfield_description.validation_message {
		    display: none;
		}
		.validation_error {
		    text-align: center;
		    color: #c11e1e;
		    font-weight: 600;
		    margin: 0 0 20px 0;
		}
	}

	li#field_1_1, li#field_1_2, li#field_1_3, li#field_1_4{
		float:left;
		width:50%;
		margin-bottom:15px;
	}

	li#field_1_5, .gform_footer.top_label{
		float:left;
		width:100%;
	}

	li#field_1_2, li#field_1_4{
	padding-left:15px;
	}
	
}

// PROJECTS ARCHIVE

.projects{
	display:flex;
	flex-wrap:wrap;
}

.project{
	width: 22%;
    background: #ebebeb;
    margin-right: 1.5%;
    margin-left: 1.5%;
    margin-bottom:4%;
	background:$light-grey;
    min-height: 526px;
	.thumbnail{
		border-bottom:4px solid $main-color;
		img{
			display:block;
			height: 190px;
    width: 100%;
		}
	}
	.content{
		padding:30px;
	}
	h2{
		margin:0;
		font-size:25px;
		line-height:1.2;
	}
	.location{
		color:darken($light-grey, 25%);
		font-size:18px;
	}
	p{
		margin:15px 0 0 0;
	}
	.button{
		margin-top:20px;
		width:180px;
	}
}

.project-filter{
	list-style-type:none;
	margin:10px 0 70px 0;
	padding:0;
	text-align:center;
	li{
		display:inline-block;
		margin-right:16px;
		background:$light-grey;
		padding:10px 25px;
		color:#111;
		font-weight:600;
		border-radius:$border-radius;
		cursor:pointer;
		transition:300ms all ease;
		&:hover{
			background:$main-color;
			color:#fff;
		}
	}
}

.project-filter li.is-checked{
	background:$main-color;
	color:#fff;	
}

.filters-select{
	display:none;
}

// PROJECTS SINGLE
.project-location {
    font-size: 22px;
    color: #bfbfbf;
    font-weight: 600;
    width: 500px;
    max-width: 100%;
    margin: -75px auto 65px auto;
    text-align: center;
}

.project-content{
	width:1200px;
	margin:0 auto;
	.slider{
		background:#eee;
		margin-bottom:60px !important;
		.slide{
			height:450px;
		}
		.slick-dots{
			background:$light-grey;
			bottom:-24px;
			padding:7px 0;
			li{
				vertical-align:middle;
				margin:0 2px;
			}
		}
	}
	.left-content{
		float:left;
		width:calc(100% - 300px);
		padding-right:80px;
		font-size:18px;
	}
	.details{
		float:left;
		width:300px;
	}
	.slick-dots li button:before {
    font-family: 'slick';
    font-size: 13px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #999;
}
.slick-dots li.slick-active button:before{
	opacity:1;
	color:$main-color;
}
}

.feature{
	p{
		font-size:19px;
		line-height:30px;
		span{
			display:block;
			font-weight:900;
			font-size:24px;
		}
	}
}

// History
.history-page{
	.row{
		display:flex;
		margin-bottom:60px;
		p{
			font-size:22px;
			&:first-of-type{
				margin-top:0;
			}
			&:last-of-type{
				margin-bottom:0;
			}
		}
	}
}

.row-1, .row-3{
	.left-content{
		float:left;
		width:calc(100% - 520px);
		padding-right:60px;
	}
	.right-image{
		float:left;
		width:520px;
	}
}

.row-2{
	.right-content{
		float:left;
		width:calc(100% - 400px);
		padding-left:60px;
	}
	.left-image{
		float:left;
		width:400px;
	}
}

.history-feature{
	width:1150px;
	max-width:100%;
	margin:120px auto 120px auto;
	text-align:center;
	p{
		font-size:50px;
		font-weight:800;
		line-height:1.3;
		margin:0;
	}
	ul{
		list-style-type:none;
		font-weight:900;
		text-transform:uppercase;
		color:#b4b4b4;
		font-size:22px;
		margin:15px 0 0 0;
		padding: 0;
		li{
			display:inline-block;
			padding:0 5px;
		}
	}
}

.assets{
	width:1150px;
	margin:0 auto;
	max-width:100%;
	columns: 2;
    column-gap: 50px;
	div{
		margin-bottom:30px;
	}
	h3{
		font-size:27px;
		font-weight:900;
		margin:0 0 0 0;
		line-height:1.2;
	}
	p{
		font-size:22px;
		margin:0 0 0 0;
	}
}

//service single
.service-page{
	display: flex;
	.left{
		float:left;
		width: 50%;
	    font-size: 18px;
	    background: #ebebeb;
	    padding: 40px;
	}
	.right{
		float:left;
		width: calc(50% - 100px);
		margin-left:100px;
		img {
			max-width: 100%;
			width: 100%;
		}
	}
	a{
		color:$main-color;
	}
}

// SINGLE NEWS
.single-news-post .left-page{
	padding-right:50px;
	p{
		font-size:18px;
		&:last-of-type{
			margin-bottom:0;
		}
	}
}