header{
	background:$light-grey;
}

.menu-section{
	width:$wrapper-width;
	position:absolute;
	z-index:100;
	right:0;
	left:0;
	margin:auto;
}

.top-bar{
	background:$light-grey;
	display:flex;
	justify-content:space-between;
	align-items:center;
	padding:20px 30px;
	p{
		margin:0;
		line-height:1;
		color:$grey-font;
		span{
			margin-left:15px;
		}
	}
}

.header-main{
	background:#fff;
	display:flex;
	justify-content:space-between;
	h1{
		margin:0;
		align-self:flex-end;
	}
	img{
		display:block;
	}
}

.site-branding{
	display:flex;
}

.main-navigation{
	display:flex;
	align-items:center;
	padding:35px 35px 35px 0;
		ul{
		list-style:none;
		margin:0;
		padding:0;
		font-size:17px;
		li{
			display:inline-block;
			font-weight:700;
			text-transform:uppercase;
			padding-right:40px;
			&:last-of-type{
				padding-right:0;
			}
			a{
				color:#3e3e3e;
				&:hover{
					color: #c11e1e;
				}
			}
		}
	}
	.button{
		width:240px;
		padding:20px 0;
		margin-left:30px;
	}
}

.main-slider{
	.slide{
		outline:0;
		background:url('img/slide_bg.png') center center / cover;
		height:820px;
		position:relative;
		color:#fff;
		padding-top:350px;
		&:before{
			content:'';
			position:absolute;
			top:0; bottom:0;
			left:0; right:0;
			background:rgba(0,0,0,0.5);
		}
		.content{
			width:730px;
			max-width:100%;
		}
		h2{
			margin:0 0 20px 0;
			font-weight:900;
			font-size:40px;
			letter-spacing: -0.4px;
			line-height:1.12;
		}
		p{
			margin:0;
		  	font-size: 21px;
		  	font-weight: 700;
		  	line-height: 1.62;
		  	letter-spacing: -0.2px;
		}
		.button{
		margin-top: 30px;
	    display: inline-block;
	    font-size: 20px;
	    padding: 15px 30px;
		}
	}
}

.prev-slick, .next-slick{
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 40px;
    z-index: 10;
    color: #fff;
    i{
    	display:block;
    }
}

.prev-slick{
	left:30px;
}

.next-slick{
	right:30px;
}

.subpage-header-bg{
	height:260px;
	background:$light-grey;
}