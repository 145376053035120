@charset "UTF-8";
/*
Theme Name: PMcK Electrical
Theme URI: https://www.bigpixelcreative.com
Author: Big Pixel Creative
Author URI: https://www.bigpixelcreative.com
Description: Description
Version: 1.0.0
Text Domain: tmi
*/
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent;
  text-decoration: none; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: 900; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
optgroup,
textarea {
  color: #4b4b4b;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both; }

p {
  margin-bottom: 1.5em; }

dfn,
cite,
em,
i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em; }

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem; }

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

mark,
ins {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

body {
  background: #fff;
  /* Fallback for when there is no custom background color defined. */ }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: ""; }

blockquote,
q {
  quotes: "" ""; }

hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em; }

ul,
ol {
  margin: 0 0 1.5em 3em; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em; }

dt {
  font-weight: bold; }

dd {
  margin: 0 1.5em 1.5em; }

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */ }

figure {
  margin: 1em 0;
  /* Extra wide images within figure tags don't overflow the content area. */ }

table {
  margin: 0 0 1.5em;
  width: 100%; }

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 1px solid;
  border-color: #ccc #ccc #bbb;
  border-radius: 3px;
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
  padding: .6em 1em .4em; }

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  border-color: #ccc #bbb #aaa; }

button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
  border-color: #aaa #bbb #bbb; }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: #666;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px; }

select {
  border: 1px solid #ccc; }

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  color: #111; }

textarea {
  width: 100%; }

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
  transition: all 250ms ease; }

a:hover,
a:active {
  outline: 0; }

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
  display: block; }

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
  margin: 0 0 1.5em;
  overflow: hidden; }

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%; }

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%; }

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */ }

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */ }

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0; }

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em; }

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em; }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed; }

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both; }

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget {
  margin: 0 0 1.5em; }

/* Make sure select elements fit in widgets. */
.widget select {
  max-width: 100%; }

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
  display: block; }

.hentry {
  margin: 0 0 1.5em; }

.updated:not(.published) {
  display: none; }

.page-content,
.entry-content,
.entry-summary {
  margin: 1.5em 0 0; }

.page-links {
  clear: both;
  margin: 0 0 1.5em; }

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-content a {
  word-wrap: break-word; }

.bypostauthor {
  display: block; }

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none; }

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block; }

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0; }

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%; }

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block; }

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%; }

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.wp-caption .wp-caption-text {
  margin: 0.8075em 0; }

.wp-caption-text {
  text-align: center; }

/* BP STYLES */
.clear {
  clear: both; }

* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif; }

.flaticon {
  font-weight: 400 !important; }

.site {
  max-width: 2000px;
  margin: 0 auto;
  transition: transform 0.4s ease; }

.animated {
  transition: all 250ms ease; }

.bordered {
  border-radius: 50px; }

.button {
  border-radius: 50px;
  padding: 15px 20px;
  display: block;
  background: #c11e1e;
  text-align: center;
  text-transform: uppercase;
  transition: all 250ms ease;
  color: #fff;
  cursor: pointer;
  outline: 0; }

.button .flaticon {
  display: inline-block;
  margin-right: 10px; }

.button:hover {
  background: #7f1414; }

.button.green-button {
  background: #92bd3e; }

.button.green-button:hover {
  background: #749731; }

.button.large-button {
  font-size: 24px;
  font-weight: 700; }
  .button.large-button i {
    font-weight: 400;
    padding-right: 12px;
    font-size: 26px; }

.wrapper {
  margin: 0 auto;
  width: 1450px;
  position: relative; }

.sidebar {
  width: 350px; }

.left-page {
  float: left;
  width: calc(100% - 350px); }

.right-page {
  float: right;
  width: calc(100% - 350px); }

.middle-page {
  float: none;
  margin: 0 auto;
  width: calc(100% - 350px); }

.left-sidebar {
  float: left; }

.right-sidebar {
  float: right;
  padding-left: 20px; }

.hide {
  display: none; }

.mobile-menu {
  display: none; }

.section-title {
  font-size: 57px;
  font-weight: 300;
  line-height: 1.39;
  letter-spacing: -2.9px;
  text-align: center;
  margin: 0;
  color: #d0d0d0;
  position: relative;
  z-index: 2;
  display: inline-block;
  background: #fff;
  padding: 0 40px; }

.sidebar-box {
  background: #ebebeb;
  border-radius: 5px;
  margin-bottom: 60px; }
  .sidebar-box h3 {
    margin: 0;
    background: #c11e1e;
    color: #fff;
    text-align: center;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
    padding: 15px 0;
    font-size: 18px; }
  .sidebar-box .content {
    padding: 25px; }
  .sidebar-box p {
    font-size: 18px; }
    .sidebar-box p:nth-of-type(1) {
      margin-top: 0; }
    .sidebar-box p:last-of-type {
      margin-bottom: 0; }
  .sidebar-box ul {
    margin: 0 0 0 25px;
    padding: 0;
    font-weight: 600;
    font-size: 17px;
    list-style-type: none; }
    .sidebar-box ul a {
      color: #4b4b4b; }
    .sidebar-box ul li {
      margin-bottom: 10px;
      position: relative; }
      .sidebar-box ul li:before {
        content: '';
        position: absolute;
        height: 7px;
        width: 7px;
        background: #92bd3e;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        left: -20px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 2; }

.sidebar-newsletter input {
  border-radius: 50px;
  padding: 20px 25px;
  font-size: 18px;
  width: 100%;
  outline: 0; }

.sidebar-newsletter .button {
  display: block;
  margin: 15px auto 0 auto;
  font-size: 18px;
  font-weight: 500; }
  .sidebar-newsletter .button i {
    font-weight: normal;
    margin-right: 5px; }

header {
  background: #ebebeb; }

.menu-section {
  width: 1450px;
  position: absolute;
  z-index: 100;
  right: 0;
  left: 0;
  margin: auto; }

.top-bar {
  background: #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px; }
  .top-bar p {
    margin: 0;
    line-height: 1;
    color: #ababab; }
    .top-bar p span {
      margin-left: 15px; }

.header-main {
  background: #fff;
  display: flex;
  justify-content: space-between; }
  .header-main h1 {
    margin: 0;
    align-self: flex-end; }
  .header-main img {
    display: block; }

.site-branding {
  display: flex; }

.main-navigation {
  display: flex;
  align-items: center;
  padding: 35px 35px 35px 0; }
  .main-navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 17px; }
    .main-navigation ul li {
      display: inline-block;
      font-weight: 700;
      text-transform: uppercase;
      padding-right: 40px; }
      .main-navigation ul li:last-of-type {
        padding-right: 0; }
      .main-navigation ul li a {
        color: #3e3e3e; }
        .main-navigation ul li a:hover {
          color: #c11e1e; }
  .main-navigation .button {
    width: 240px;
    padding: 20px 0;
    margin-left: 30px; }

.main-slider .slide {
  outline: 0;
  background: url("img/slide_bg.png") center center/cover;
  height: 820px;
  position: relative;
  color: #fff;
  padding-top: 350px; }
  .main-slider .slide:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5); }
  .main-slider .slide .content {
    width: 730px;
    max-width: 100%; }
  .main-slider .slide h2 {
    margin: 0 0 20px 0;
    font-weight: 900;
    font-size: 40px;
    letter-spacing: -0.4px;
    line-height: 1.12; }
  .main-slider .slide p {
    margin: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1.62;
    letter-spacing: -0.2px; }
  .main-slider .slide .button {
    margin-top: 30px;
    display: inline-block;
    font-size: 20px;
    padding: 15px 30px; }

.prev-slick, .next-slick {
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 40px;
  z-index: 10;
  color: #fff; }
  .prev-slick i, .next-slick i {
    display: block; }

.prev-slick {
  left: 30px; }

.next-slick {
  right: 30px; }

.subpage-header-bg {
  height: 260px;
  background: #ebebeb; }

.home-main {
  padding: 110px 0; }

.sectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px; }

.single-sector {
  width: 238px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .single-sector h2 {
    font-size: 27px;
    line-height: 1.22;
    margin: 30px 0 12px 0;
    letter-spacing: -0.3px; }
  .single-sector p {
    font-size: 18px;
    line-height: 1.39;
    margin: 0 0 16px 0; }
  .single-sector a {
    color: #92bd3e;
    font-size: 18px;
    line-height: 1.39; }

.icon {
  background: #f4f4f4;
  border-radius: 45px;
  margin: 0 auto;
  height: 172px;
  width: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px; }

.home-intro {
  margin-bottom: 150px;
  text-align: center; }
  .home-intro p {
    font-size: 50px;
    letter-spacing: -0.5px;
    font-weight: 300;
    line-height: 1.30;
    width: 1180px;
    max-width: 100%;
    margin: 0 auto 50px auto; }
  .home-intro .button {
    width: 290px;
    margin: 0 auto;
    padding: 27px 0; }

.home-services {
  position: relative;
  background: #ebebeb;
  display: flex;
  margin-bottom: 140px; }
  .home-services .wrapper {
    position: initial; }
  .home-services .left {
    position: relative;
    z-index: 1;
    float: left;
    width: 40%;
    color: #fff;
    display: flex;
    align-items: center;
    height: 100%;
    text-align: right; }
    .home-services .left > div {
      width: 400px;
      max-width: 100%; }
    .home-services .left h2 {
      font-size: 40px;
      margin: 0; }
    .home-services .left p {
      font-size: 20px; }
    .home-services .left .button {
      width: 250px;
      margin-left: auto; }
  .home-services .right {
    position: relative;
    z-index: 1;
    float: left;
    width: 60%;
    padding: 120px 0 80px 80px;
    font-size: 0; }
    .home-services .right h3 {
      margin: 0 0 15px 0;
      font-size: 30px;
      line-height: 1.2; }
    .home-services .right p {
      font-size: 16px;
      margin: 0 0 15px 0; }
    .home-services .right a {
      color: #92bd3e;
      font-size: 18px; }

.left-background {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 42%;
  background: url("img/service_bg.jpg") center center/cover; }
  .left-background:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 0, 0, 0.7); }

.right-background {
  background: #ebebeb;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60%; }

.single-service {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin-bottom: 40px;
  padding-right: 50px; }

.latest-news {
  text-align: center; }
  .latest-news hr {
    height: 2px;
    margin: 0;
    background: #d1d1d1;
    top: -35px;
    z-index: -1;
    position: relative; }

.single-news {
  float: left;
  width: 31%;
  margin-right: 3.5%; }
  .single-news:nth-of-type(3) {
    margin-right: 0; }
  .single-news .thumb {
    border-radius: 45px;
    overflow: hidden;
    position: relative; }
    .single-news .thumb img {
      display: block; }
    .single-news .thumb:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0;
      right: 0;
      background: transparent;
      border-radius: 45px;
      border: 10px solid rgba(255, 255, 255, 0.7); }
  .single-news h3 {
    font-size: 23px;
    margin: 0;
    width: 300px;
    max-width: 100%;
    margin: 30px auto 18px auto;
    line-height: 1.25; }
  .single-news p {
    font-size: 20px;
    line-height: 1.25;
    margin: 0 0 15px 0; }
  .single-news a {
    color: #92bd3e;
    display: block;
    font-size: 20px;
    line-height: 1.17;
    font-weight: 700; }

.view-all-news {
  color: #92bd3e;
  text-transform: uppercase;
  line-height: 1.39;
  font-size: 18px;
  font-weight: 700;
  display: block;
  margin-bottom: 80px; }

.site-main {
  padding: 70px 0 110px 0; }

.subpage-title {
  position: relative;
  text-align: center;
  margin-bottom: 80px; }
  .subpage-title:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: #d1d1d1; }
  .subpage-title h1 {
    text-transform: uppercase;
    font-size: 45px;
    font-weight: 500;
    line-height: 1.39;
    letter-spacing: -2px;
    text-align: center;
    margin: 0;
    color: #d0d0d0;
    background: #fff;
    padding: 0 40px;
    display: inline-block;
    position: relative;
    z-index: 1; }

.subpage-intro {
  font-size: 18px;
  color: #d0d0d0;
  width: 500px;
  max-width: 100%;
  margin: -65px auto 80px auto;
  text-align: center; }

.services-archive {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .services-archive .third {
    width: 33.333%;
    padding: 0 20px;
    margin-bottom: 40px; }
  .services-archive .service {
    background: #ebebeb;
    padding: 35px;
    height: 100%; }
    .services-archive .service h2 {
      font-size: 30px;
      line-height: 1.25;
      margin: 0; }
    .services-archive .service .button {
      width: 200px; }
    .services-archive .service .thumb {
      margin: -35px -35px 30px -35px;
      border-bottom: 6px solid #c11e1e; }
      .services-archive .service .thumb img {
        display: block;
        width: 100%; }

.news-single-archive {
  margin-bottom: 60px; }
  .news-single-archive:last-of-type {
    margin-bottom: 0; }
  .news-single-archive .thumb {
    float: left;
    border-radius: 45px;
    overflow: hidden;
    position: relative;
    width: 350px; }
    .news-single-archive .thumb img {
      display: block; }
    .news-single-archive .thumb:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0;
      right: 0;
      background: transparent;
      border-radius: 45px;
      border: 10px solid rgba(255, 255, 255, 0.7);
      pointer-events: none; }
  .news-single-archive .content {
    float: left;
    width: calc(100% - 350px);
    padding: 0 35px; }
    .news-single-archive .content h2 {
      margin: 0; }
    .news-single-archive .content .date {
      color: #c11e1e;
      font-weight: 600; }
    .news-single-archive .content p {
      font-size: 18px;
      margin: 20px 0; }
    .news-single-archive .content .button {
      width: 200px; }

.contact-page .left-page {
  padding-right: 80px; }

.contact-page form ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.contact-page form input[type="text"], .contact-page form input[type="email"], .contact-page form textarea {
  width: 100%;
  border-radius: 50px;
  padding: 15px 25px;
  outline: 0;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  max-width: 100%;
  background: #f3f3f3;
  border: 0;
  resize: none; }

.contact-page form textarea {
  padding: 25px; }

.contact-page form .button {
  font-size: 18px;
  margin-top: 10px;
  width: 150px;
  text-align: center;
  font-weight: 500; }

.contact-page form .gfield_description.validation_message {
  display: none; }

.contact-page form .validation_error {
  text-align: center;
  color: #c11e1e;
  font-weight: 600;
  margin: 0 0 20px 0; }

.contact-page li#field_1_1, .contact-page li#field_1_2, .contact-page li#field_1_3, .contact-page li#field_1_4 {
  float: left;
  width: 50%;
  margin-bottom: 15px; }

.contact-page li#field_1_5, .contact-page .gform_footer.top_label {
  float: left;
  width: 100%; }

.contact-page li#field_1_2, .contact-page li#field_1_4 {
  padding-left: 15px; }

.projects {
  display: flex;
  flex-wrap: wrap; }

.project {
  width: 22%;
  background: #ebebeb;
  margin-right: 1.5%;
  margin-left: 1.5%;
  margin-bottom: 4%;
  background: #ebebeb;
  min-height: 526px; }
  .project .thumbnail {
    border-bottom: 4px solid #c11e1e; }
    .project .thumbnail img {
      display: block;
      height: 190px;
      width: 100%; }
  .project .content {
    padding: 30px; }
  .project h2 {
    margin: 0;
    font-size: 25px;
    line-height: 1.2; }
  .project .location {
    color: #ababab;
    font-size: 18px; }
  .project p {
    margin: 15px 0 0 0; }
  .project .button {
    margin-top: 20px;
    width: 180px; }

.project-filter {
  list-style-type: none;
  margin: 10px 0 70px 0;
  padding: 0;
  text-align: center; }
  .project-filter li {
    display: inline-block;
    margin-right: 16px;
    background: #ebebeb;
    padding: 10px 25px;
    color: #111;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    transition: 300ms all ease; }
    .project-filter li:hover {
      background: #c11e1e;
      color: #fff; }

.project-filter li.is-checked {
  background: #c11e1e;
  color: #fff; }

.filters-select {
  display: none; }

.project-location {
  font-size: 22px;
  color: #bfbfbf;
  font-weight: 600;
  width: 500px;
  max-width: 100%;
  margin: -75px auto 65px auto;
  text-align: center; }

.project-content {
  width: 1200px;
  margin: 0 auto; }
  .project-content .slider {
    background: #eee;
    margin-bottom: 60px !important; }
    .project-content .slider .slide {
      height: 450px; }
    .project-content .slider .slick-dots {
      background: #ebebeb;
      bottom: -24px;
      padding: 7px 0; }
      .project-content .slider .slick-dots li {
        vertical-align: middle;
        margin: 0 2px; }
  .project-content .left-content {
    float: left;
    width: calc(100% - 300px);
    padding-right: 80px;
    font-size: 18px; }
  .project-content .details {
    float: left;
    width: 300px; }
  .project-content .slick-dots li button:before {
    font-family: 'slick';
    font-size: 13px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #999; }
  .project-content .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #c11e1e; }

.feature p {
  font-size: 19px;
  line-height: 30px; }
  .feature p span {
    display: block;
    font-weight: 900;
    font-size: 24px; }

.history-page .row {
  display: flex;
  margin-bottom: 60px; }
  .history-page .row p {
    font-size: 22px; }
    .history-page .row p:first-of-type {
      margin-top: 0; }
    .history-page .row p:last-of-type {
      margin-bottom: 0; }

.row-1 .left-content, .row-3 .left-content {
  float: left;
  width: calc(100% - 520px);
  padding-right: 60px; }

.row-1 .right-image, .row-3 .right-image {
  float: left;
  width: 520px; }

.row-2 .right-content {
  float: left;
  width: calc(100% - 400px);
  padding-left: 60px; }

.row-2 .left-image {
  float: left;
  width: 400px; }

.history-feature {
  width: 1150px;
  max-width: 100%;
  margin: 120px auto 120px auto;
  text-align: center; }
  .history-feature p {
    font-size: 50px;
    font-weight: 800;
    line-height: 1.3;
    margin: 0; }
  .history-feature ul {
    list-style-type: none;
    font-weight: 900;
    text-transform: uppercase;
    color: #b4b4b4;
    font-size: 22px;
    margin: 15px 0 0 0;
    padding: 0; }
    .history-feature ul li {
      display: inline-block;
      padding: 0 5px; }

.assets {
  width: 1150px;
  margin: 0 auto;
  max-width: 100%;
  columns: 2;
  column-gap: 50px; }
  .assets div {
    margin-bottom: 30px; }
  .assets h3 {
    font-size: 27px;
    font-weight: 900;
    margin: 0 0 0 0;
    line-height: 1.2; }
  .assets p {
    font-size: 22px;
    margin: 0 0 0 0; }

.service-page {
  display: flex; }
  .service-page .left {
    float: left;
    width: 50%;
    font-size: 18px;
    background: #ebebeb;
    padding: 40px; }
  .service-page .right {
    float: left;
    width: calc(50% - 100px);
    margin-left: 100px; }
    .service-page .right img {
      max-width: 100%;
      width: 100%; }
  .service-page a {
    color: #c11e1e; }

.single-news-post .left-page {
  padding-right: 50px; }
  .single-news-post .left-page p {
    font-size: 18px; }
    .single-news-post .left-page p:last-of-type {
      margin-bottom: 0; }

#map {
  height: 820px; }

.accreditations {
  background: #f4f4f4;
  padding: 43px 0;
  text-align: center; }
  .accreditations .single-accreditation {
    display: inline-block;
    margin: 0 20px; }
    .accreditations .single-accreditation img {
      filter: grayscale(100%); }

.newsletter {
  background: #a11b1b;
  padding: 55px 0;
  flex: 1; }
  .newsletter .wrapper {
    display: flex;
    justify-content: space-between; }
  .newsletter form .button {
    display: inline-block;
    height: 90px;
    border: 0;
    width: 260px; }
  .newsletter form input {
    color: #cac9c9;
    font-size: 20px;
    height: 90px;
    border-radius: 45px;
    padding: 35px 50px;
    font-family: "Lato", sans-serif;
    outline: 0;
    font-weight: 700;
    margin-right: 15px;
    width: 440px; }

.social {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end; }
  .social li {
    display: inline-block;
    padding-left: 20px; }
    .social li a svg circle {
      transition: all 300ms ease; }
    .social li a:hover svg circle {
      fill: #92bd3e !important; }
  .social svg {
    height: 64px;
    width: 64px; }

footer {
  background: #c11e1e;
  padding: 110px 0 70px 0;
  color: #fff; }
  footer .column {
    width: 25%;
    float: left;
    padding-right: 60px; }
  footer h3 {
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 25px 0; }
  footer p {
    font-size: 18px;
    margin: 0 0 15px 0; }
  footer ul {
    list-style: none;
    margin: 0 0 0 15px;
    padding: 0;
    font-size: 18px; }
    footer ul li {
      margin-bottom: 10px;
      position: relative; }
      footer ul li:before {
        content: '';
        position: absolute;
        height: 7px;
        width: 7px;
        background: #92bd3e;
        border-radius: 50%;
        left: -18px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 2; }

.about-column p {
  line-height: 24px;
  margin: 0 0 10px 0;
  text-indent: 50px; }

.about-column a {
  color: #92bd3e;
  font-size: 18px; }
  .about-column a:hover {
    color: #749731; }

.site-info {
  font-size: 17px;
  text-align: center;
  margin-top: 60px; }
  .site-info p {
    margin: 0; }
  .site-info a {
    color: #fff; }

.two-column {
  column-count: 2;
  column-gap: 40px; }

a {
  text-decoration: none;
  color: #fff; }

a:hover {
  text-decoration: none;
  color: #cccccc; }

a:active {
  text-decoration: none;
  color: #1ea584; }

@media (max-width: 1450px) {
  .wrapper {
    width: 100%;
    padding: 0 40px; }
  .menu-section {
    width: 100%; }
  .top-bar {
    padding: 20px 25px; }
  .main-navigation {
    padding: 35px 40px 35px 0; }
  .main-slider .wrapper {
    padding: 0 100px; }
  .sectors {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px; }
  .home-intro {
    margin-bottom: 130px; }
  .single-sector {
    padding: 0 30px;
    margin-bottom: 50px;
    width: calc(100% / 3);
    flex-direction: column; }
  .single-service {
    width: 100%; }
  .accreditations .single-accreditation {
    margin: 20px; }
  footer .column p:last-child strong {
    display: block; }
  .project-content {
    max-width: 1200px;
    width: 100%; }
  .service-page .left {
    width: 55%; }
  .service-page .right {
    width: calc(45% - 20px);
    margin-left: 20px; }
  .subpage-header-bg {
    height: 215px;
    background: #fff;
    border-bottom: 1px solid #ebebeb; } }

@media (max-width: 1200px) {
  .top-bar {
    flex-wrap: wrap;
    justify-content: center; }
    .top-bar p {
      width: 100%;
      text-align: center; }
      .top-bar p span {
        margin: 0 10px; }
    .top-bar p:last-child {
      margin-top: 10px; }
  .main-navigation .menu-primary-container {
    display: none; }
  .main-navigation a.button {
    margin-left: 0; }
  .menu-button {
    position: relative;
    background: #c11e1e;
    height: 80px;
    border-radius: 25px;
    margin-left: 20px;
    width: 80px;
    cursor: pointer; }
    .menu-button > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%; }
      .menu-button > span > span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        width: 60%;
        height: 3px;
        border-radius: 10px;
        transition: background 0.4s ease; }
        .menu-button > span > span:before {
          content: '';
          position: absolute;
          left: 0;
          top: -10px;
          height: 3px;
          width: 80%;
          background: #fff;
          border-radius: 10px;
          transition: all 0.4s ease; }
        .menu-button > span > span:after {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          height: 3px;
          width: 90%;
          background: #fff;
          border-radius: 10px;
          transition: all 0.4s ease; }
    .menu-button:hover > span > span:before, .menu-button:hover > span > span:after {
      width: 100%; }
  .menu-button.active > span > span {
    background: transparent; }
    .menu-button.active > span > span:before {
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg); }
    .menu-button.active > span > span:after {
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg); }
  #page.active {
    transform: translateX(calc(-100% + 140px)); }
  .mobile-menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 140px);
    height: 100%;
    background: #c11e1e;
    transform: translateX(calc(100% + 140px));
    transition: transform 0.4s ease; }
    .mobile-menu > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .mobile-menu > div ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        text-align: center; }
        .mobile-menu > div ul li {
          margin: 20px 0;
          position: relative; }
          .mobile-menu > div ul li:not(:nth-last-child(-n+2)):after {
            content: '';
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
            width: 30px;
            height: 2px;
            background: rgba(255, 255, 255, 0.3); }
          .mobile-menu > div ul li a {
            font-size: 28px; }
        .mobile-menu > div ul .mobile-cta {
          position: relative;
          border-radius: 50px;
          padding: 15px 20px;
          display: block;
          background: #fff;
          text-align: center;
          text-transform: uppercase;
          transition: all 250ms ease;
          cursor: pointer;
          outline: 0; }
          .mobile-menu > div ul .mobile-cta a {
            color: #c11e1e; }
          .mobile-menu > div ul .mobile-cta:before {
            color: #c11e1e;
            font-weight: 700;
            font-size: 24px;
            margin-right: 10px; }
  .mobile-menu.active {
    transform: translateX(0%); }
  .subpage-header-bg {
    height: 240px; }
  .home-intro {
    padding: 0 60px; }
    .home-intro p {
      font-size: 40px;
      margin: 0 auto 40px auto; }
    .home-intro .button {
      width: 270px;
      margin: 0 auto;
      padding: 20px 0;
      font-size: 22px; }
  .button.large-button {
    font-size: 22px; }
  .left-background {
    width: 100%; }
  .right-background {
    width: calc(100% - 80px);
    top: 500px;
    right: 40px;
    height: calc(100% - 540px); }
  .home-services .left {
    height: 500px;
    text-align: center;
    width: 100%; }
    .home-services .left > div {
      width: 100%; }
    .home-services .left .button {
      margin: 0 auto; }
  .home-services .right {
    width: 100%;
    padding: 80px; }
    .home-services .right .single-service {
      padding-right: 0; }
  .single-news {
    width: 100%;
    margin-right: 0; }
    .single-news .thumb {
      width: 300px;
      float: left; }
    .single-news *:not(:first-child) {
      float: left;
      width: calc(100% - 350px);
      clear: none;
      text-align: left;
      margin-left: 50px; }
    .single-news h3 {
      margin: 10px auto 18px auto; }
  .latest-news > div > div:not(:nth-last-child(-n+2)) {
    margin-bottom: 50px; }
  .newsletter .wrapper {
    flex-wrap: wrap;
    justify-content: center; }
    .newsletter .wrapper form {
      width: 100%;
      text-align: center; }
    .newsletter .wrapper .social {
      margin-top: 50px;
      justify-content: center; }
  .newsletter form .button {
    height: 80px; }
  .newsletter form input {
    height: 80px; }
  footer .column:first-child {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    padding-right: 0; }
  footer .column:nth-child(2), footer .column:nth-child(3) {
    width: 50%;
    padding-right: 0; }
    footer .column:nth-child(2) .two-column, footer .column:nth-child(3) .two-column {
      column-count: 1; }
  footer .column:nth-child(4) {
    margin-top: 50px;
    width: 100%;
    padding-right: 0; }
  .project {
    width: calc((100% / 3) - 3%); }
  .services-archive .third {
    width: 50%; }
  .service-page .left ul {
    margin: 0; }
  .left-page {
    width: 100%; }
  .contact-page .left-page {
    padding: 0; }
  .sidebar {
    margin-top: 50px;
    width: 100%; }
  .right-sidebar {
    padding-left: 0; }
  .page-template-news .right-sidebar {
    display: none; }
  .single-post .right-sidebar {
    display: none; }
  .contact-page .right-sidebar .content {
    display: flex; }
    .contact-page .right-sidebar .content p {
      width: calc(100% / 3);
      margin: 0; }
  .row-1 .left-content, .row-3 .left-content {
    width: 60%; }
  .row-1 .right-image, .row-3 .right-image {
    width: 40%; }
  .row-2 .left-image {
    width: 40%; }
  .row-2 .right-content {
    width: 60%; }
  .single-news-post .left-page {
    padding-right: 0; }
  .project-filter {
    margin: 0 0 50px 0; }
    .project-filter li {
      margin-bottom: 16px; } }

@media (max-width: 900px) {
  .main-navigation a.button {
    display: none; }
  .main-slider .slide .content {
    text-align: center; }
    .main-slider .slide .content .button {
      margin: 30px auto 0 auto; }
  .single-sector {
    width: 100%;
    text-align: left;
    padding-left: 222px;
    position: relative;
    min-height: 170px; }
    .single-sector h2 {
      margin: 10px 0 12px; }
    .single-sector .icon {
      position: absolute;
      top: 0;
      left: 0; }
    .single-sector:last-child {
      margin-bottom: 0; }
  .single-news *:last-child {
    float: right; }
  .home-intro {
    margin-bottom: 90px; }
  .home-services {
    background: url(img/service_bg.jpg) center center/cover;
    position: relative;
    margin-bottom: 80px; }
    .home-services:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 0, 0, 0.7); }
    .home-services .right {
      background: #ebebeb;
      margin-bottom: 40px; }
    .home-services .left {
      height: auto;
      display: block;
      padding: 60px 0; }
  .left-background, .right-background {
    display: none; }
  .newsletter {
    text-align: center; }
    .newsletter form input {
      width: 100%; }
    .newsletter button {
      margin-top: 20px; }
  footer .column:first-child {
    text-align: center;
    margin-bottom: 0; }
  footer .column:nth-child(2), footer .column:nth-child(3), footer .column:nth-child(4) {
    display: none; }
  .project {
    width: calc(50% - 3%); }
  .project-filter li {
    margin-bottom: 15px; }
  .project-location {
    width: 100%; }
  .project-content .details {
    width: 100%; }
  .project-content .left-content {
    width: 100%;
    padding: 0; }
  .service-page {
    flex-wrap: wrap; }
    .service-page .left {
      width: 100%; }
    .service-page .right {
      width: 100%;
      margin: 30px 0 0;
      text-align: center; }
      .service-page .right img {
        width: auto; }
  .history-page .row {
    flex-wrap: wrap; }
    .history-page .row p {
      font-size: 20px; }
  .history-feature {
    margin: 65px auto 80px auto; }
    .history-feature p {
      font-size: 36px; }
    .history-feature ul {
      font-size: 20px; }
  .assets {
    margin-bottom: -60px; }
    .assets h3 {
      font-size: 22px; }
    .assets p {
      font-size: 19px; }
  .row-1 .left-content, .row-3 .left-content, .row-2 .right-content {
    width: 100%;
    padding: 0 0 30px;
    order: 1; }
  .row-1 .right-image, .row-3 .right-image, .row-2 .left-image {
    width: 100%;
    text-align: center;
    order: 2; }
  .row-1 .right-image img, .row-3 .right-image img, .row-2 .left-image img {
    max-width: 100%;
    width: auto; }
  .assets {
    columns: 1; }
  .news-single-archive .content {
    width: calc(100% - 280px); }
    .news-single-archive .content p {
      font-size: 16px;
      margin: 10px 0; }
    .news-single-archive .content .button {
      width: 160px;
      font-size: 15px; }
    .news-single-archive .content h2 {
      font-size: 20px;
      line-height: 1.3; }
  .news-single-archive .thumb {
    width: 280px; }
  .contact-page form input[type="text"], .contact-page form input[type="email"], .contact-page form textarea {
    padding: 13px 25px;
    font-size: 16px; }
  .sidebar-box {
    margin-bottom: 0; }
    .sidebar-box h3, .sidebar-box p {
      font-size: 17px; }
  #map {
    height: 700px; } }

@media (max-width: 700px) {
  .menu-button {
    height: 60px;
    width: 60px; }
  .subpage-header-bg {
    height: 220px; }
  .top-bar p {
    font-size: 14px; }
  .main-slider {
    margin-top: 208px; }
    .main-slider .slide {
      padding-top: 0;
      height: 600px; }
      .main-slider .slide h2 {
        font-size: 32px; }
      .main-slider .slide p {
        font-size: 19px;
        font-weight: 500; }
      .main-slider .slide .wrapper {
        padding: 0 40px;
        height: 100%;
        width: calc(100% - 80px); }
        .main-slider .slide .wrapper .content {
          width: calc(100% - 80px);
          position: absolute;
          top: 50%;
          transform: translateY(-50%); }
  .home-intro {
    padding: 0; }
    .home-intro p {
      font-size: 34px; }
  .button.large-button {
    font-size: 20px; }
  .home-intro .button {
    padding: 15px 0;
    font-size: 20px; }
  .home-main {
    padding: 60px 0; }
  .home-services .right {
    width: 100%;
    padding: 40px; }
  .home-services .right {
    padding: 40px; }
    .home-services .right h3 {
      font-size: 26px; }
  .home-services .right .single-service:last-of-type {
    margin-bottom: 0; }
  .home-services .left p {
    font-size: 18px; }
  .latest-news > div > div:not(:nth-last-child(-n+2)) {
    margin-bottom: 60px; }
  .section-title, .subpage-title h1 {
    font-size: 48px;
    line-height: 1.2; }
  .view-all-news {
    margin-bottom: 50px; }
  .newsletter form input {
    font-size: 18px; }
  .social svg {
    height: 55px; }
  .single-sector {
    padding-left: 205px;
    padding-right: 0; }
    .single-sector p {
      font-size: 17px; }
  .single-news {
    padding: 0 40px; }
    .single-news p {
      font-size: 18px; }
    .single-news h3 {
      font-size: 21px; }
    .single-news .thumb {
      width: 100%;
      margin-bottom: 10px; }
      .single-news .thumb img {
        width: 100%; }
    .single-news *:not(:first-child) {
      width: 100%;
      margin-left: 0;
      padding: 0 20px; }
  .project {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    min-height: initial; }
    .project .thumbnail img {
      height: auto;
      max-height: 400px; }
  .project-filter {
    display: none; }
  .filters-select {
    display: block;
    font-size: 18px;
    width: 100%;
    margin: 0 auto 40px auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #d0d0d0;
    font-family: "Lato", sans-serif;
    padding: 10px 50px 10px 15px;
    width: 100%;
    background: #fff url(img/down-arrow.png);
    background-repeat: no-repeat;
    background-position: right 15px center;
    cursor: pointer;
    text-align: center; }
  .services-archive .third {
    width: 100%; }
  .news-single-archive {
    margin-bottom: 75px; }
    .news-single-archive .thumb {
      width: 100%;
      margin-bottom: 10px; }
    .news-single-archive .content {
      width: 100%;
      padding: 15px 0 0 0; }
  .contact-page .right-sidebar .content {
    flex-wrap: wrap; }
    .contact-page .right-sidebar .content p {
      width: 100%;
      margin: 0 0 20px;
      text-align: center; }
  .contact-page li#field_1_1, .contact-page li#field_1_2, .contact-page li#field_1_3, .contact-page li#field_1_4 {
    width: 100%;
    padding-left: 0; }
  .contact-page form .button {
    font-size: 16px; }
  .accreditations .single-accreditation img {
    max-width: 200px; }
  footer p {
    font-size: 16px; }
  .accreditations {
    padding: 25px 0; }
  .site-main {
    padding: 50px 0 50px 0; }
  .subpage-title {
    margin-bottom: 50px; }
  .subpage-intro {
    margin: -50px auto 50px auto; }
  .single-news-post .left-page p {
    font-size: 16px; }
  .project-location {
    font-size: 20px;
    margin: -50px auto 65px auto; }
  .project-content .slider .slide {
    height: 360px; } }

@media (max-width: 500px) {
  .wrapper {
    padding: 0 30px; }
  .main-navigation {
    padding: 35px 40px 45px 0; }
  .subpage-header-bg {
    height: 145px; }
  .menu-button {
    height: 50px;
    width: 50px; }
  .top-bar {
    display: none; }
  .header-main {
    padding-top: 10px; }
  .main-slider {
    margin-top: 140px; }
  #page.active {
    transform: translateX(calc(-100% + 120px)); }
  .mobile-menu {
    width: calc(100% - 120px);
    transform: translateX(calc(100% + 120px)); }
    .mobile-menu div ul li a {
      font-size: 20px; }
  .mobile-menu > div ul .mobile-cta {
    text-transform: initial; }
  .mobile-menu > div ul .mobile-cta:before {
    font-size: 20px; }
  .header-main h1 {
    align-self: center; }
  .prev-slick {
    left: 10px; }
  .next-slick {
    right: 10px; }
  .slick-slider .slide .wrapper {
    padding: 0 30px;
    width: 100%; }
    .slick-slider .slide .wrapper .content {
      width: calc(100% - 60px); }
  .main-slider .slide {
    height: 450px; }
    .main-slider .slide h2 {
      font-size: 25px; }
    .main-slider .slide p {
      font-size: 17px; }
  .main-slider .slide .content .button {
    margin: 20px auto 0 auto;
    font-size: 16px;
    padding: 13px; }
  .home-intro {
    padding: 0; }
    .home-intro p {
      font-size: 35px; }
  .single-sector {
    padding: 0;
    text-align: center;
    background: #f4f4f4;
    padding: 25px;
    border-radius: 10px; }
    .single-sector .icon {
      position: static;
      height: auto;
      width: auto;
      font-size: 60px; }
    .single-sector p, .single-sector a {
      font-size: 16px; }
    .single-sector h2 {
      font-size: 23px; }
  .home-services .left h2 {
    font-size: 31px; }
  .home-services .left p {
    font-size: 17px; }
  .home-services .right {
    padding: 30px; }
  .home-services .right h3 {
    font-size: 23px; }
  .section-title, .subpage-title h1 {
    font-size: 40px;
    line-height: 1; }
  .subpage-intro {
    font-size: 17px;
    margin: -50px auto 50px auto; }
  .latest-news hr, .subpage-title:before {
    display: none; }
  .view-all-news {
    font-size: 16px; }
  .single-news {
    padding: 0; }
  .accreditations .single-accreditation img {
    max-width: 100px; }
  .newsletter {
    padding: 40px 0 30px 0; }
    .newsletter form input {
      font-size: 17px;
      height: auto;
      padding: 15px 30px; }
    .newsletter form .button {
      height: auto; }
  .newsletter .wrapper .social {
    margin-top: 40px; }
    .newsletter .wrapper .social svg {
      width: 45px; }
    .newsletter .wrapper .social li:first-of-type {
      padding-left: 0; }
  footer {
    padding: 70px 0 70px 0; }
    footer p {
      font-size: 15px;
      text-indent: 0px !important; }
  .subpage-title h1 {
    padding: 0; }
  .services-archive .service {
    padding: 30px; }
    .services-archive .service h2 {
      font-size: 25px;
      line-height: 1.25;
      margin: 0; }
    .services-archive .service p {
      font-size: 15px; }
    .services-archive .service .button {
      max-width: 100%; }
    .services-archive .service .thumb {
      margin: -30px -30px 25px -30px; }
  .subpage-title {
    margin-bottom: 60px; }
  .services-archive .third {
    padding: 0; }
  .news-single-archive .content {
    padding: 10px 0 0 0; }
  .home-intro {
    margin-bottom: 80px; }
    .home-intro p {
      font-size: 28px;
      margin: 0 auto 30px auto; }
  .home-intro .button {
    padding: 12px 0;
    font-size: 18px;
    width: 220px; }
  .button.large-button {
    font-size: 18px;
    max-width: 100%; }
    .button.large-button i {
      font-size: 20px; }
  .home-services {
    margin-bottom: 60px; }
  .service-page .left {
    font-size: 17px;
    padding: 30px; }
    .service-page .left p:first-of-type {
      margin-top: 0; }
  .history-page .row {
    flex-wrap: wrap;
    margin-bottom: 30px; }
    .history-page .row p {
      font-size: 18px; }
  .history-feature {
    margin: 60px auto 60px auto; }
    .history-feature p {
      font-size: 26px; }
    .history-feature ul {
      font-size: 16px; }
  .assets {
    margin-bottom: -20px; }
    .assets h3 {
      font-size: 20px; }
    .assets p {
      font-size: 18px; }
  .contact-page form textarea {
    padding: 25px;
    height: 200px;
    padding: 26px 25px !important; }
  .contact-page form .button {
    font-size: 16px;
    margin: 10px auto 0 auto; }
  #map {
    height: 500px; }
  .project .thumbnail img {
    height: auto;
    max-height: 280px; }
  .project h2 {
    font-size: 22px; }
  .project .location {
    font-size: 17px; }
  .project-content .slider .slide {
    height: 250px; }
  .project-content .slider .slick-dots {
    padding: 0; } }

@media (max-width: 370px) {
  .accreditations .single-accreditation {
    margin: 15px; }
    .accreditations .single-accreditation img {
      max-width: 85px; }
  .main-navigation {
    padding: 35px 15px 45px 0; }
  .mobile-menu div ul li a {
    font-size: 18px; }
  .subpage-title {
    margin-bottom: 50px; }
    .subpage-title h1 {
      font-size: 35px; }
  .subpage-intro {
    font-size: 16px; }
  .service-page .left {
    font-size: 16px;
    padding: 25px; }
    .service-page .left ul {
      padding-left: 15px; }
      .service-page .left ul li {
        margin-bottom: 5px; }
  .history-page .row {
    flex-wrap: wrap; }
    .history-page .row p {
      font-size: 16px; }
  .history-feature {
    margin: 60px auto 60px auto; }
    .history-feature p {
      font-size: 24px; }
    .history-feature ul {
      font-size: 16px; }
      .history-feature ul li {
        display: block; }
  .assets h3 {
    font-size: 19px; }
  .assets p {
    font-size: 16px; }
  .sidebar-box h2, .sidebar-box p {
    font-size: 16px; }
  .contact-page form input[type="text"], .contact-page form input[type="email"], .contact-page form textarea {
    font-size: 15px; }
  .project-content .slider {
    background: #eee;
    margin-bottom: 40px !important; }
  .project-content .slider .slide {
    height: 180px; } }
