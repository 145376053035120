.home-main{
	padding:110px 0;
}
.sectors{
	display:flex;
	justify-content:space-between;
	margin-bottom:150px;
}

.single-sector{
	width:238px;
	text-align:center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
	h2{
		font-size: 27px;
  		line-height: 1.22;
  		margin: 30px 0 12px 0;
  		letter-spacing: -0.3px;
	}
	p{
		font-size: 18px;
  		line-height: 1.39;
  		margin:0 0 16px 0;
	}
	a{
		color:$green;
		font-size: 18px;
  		line-height: 1.39;
	}
}

.icon{
	background:#f4f4f4;
	border-radius:45px;
	margin:0 auto;
	height:172px;
	width:172px;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:70px;
}

.home-intro{
	margin-bottom:150px;
	text-align:center;
	p{
		font-size:50px;
		letter-spacing: -0.5px;
		font-weight:300;
		line-height:1.30;
		width:1180px;
		max-width:100%;
		margin:0 auto 50px auto;
	}
	.button{
		width:290px;
		margin:0 auto;
		padding:27px 0;
	}
}

.home-services{
	position:relative;
	background:$light-grey;
	display:flex;
	margin-bottom:140px;
	.wrapper{
		position:initial;
	}
	.left{
		position:relative;
		z-index:1;
		float:left;
		width:40%;
		color:#fff;
		display:flex;
		align-items:center;
		height: 100%;
		text-align:right;
		>div{
			width:400px;
			max-width:100%;
		}
		h2{
			font-size:40px;
			margin:0;
		}
		p{
			font-size:20px;
		}
		.button{
			width:250px;
			margin-left: auto;
		}
	}
	.right{
		position:relative;
		z-index:1;
		float:left;
		width:60%;
		padding:120px 0 80px 80px;
		font-size:0;
		h3{
			margin:0 0 15px 0;
			font-size:30px;
			line-height:1.2;
		}
		p{
			font-size:16px;
			margin:0 0 15px 0;
		}
		a{
			color:$green;
			font-size:18px;
		}
	}
}

.left-background{
		position:absolute;
		left:0;
		top:0;
		bottom:0;
		width:42%;
		background:url('img/service_bg.jpg') center center / cover;
		&:after{
			content:'';
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			background:rgba(255, 0, 0, 0.7);
		}
}

.right-background{
		background:$light-grey;
		position:absolute;
		right:0;
		top:0;
		bottom:0;
		width:60%;
}

.single-service{
	display: inline-block;
    vertical-align: top;
	width:50%;
	margin-bottom:40px;
	padding-right:50px;
}

.latest-news{
	text-align:center;
	hr{
		height:2px;
		margin:0;
		background:#d1d1d1;
		top: -35px;
    	z-index: -1;
    	position: relative;
	}
}


.single-news{
	float:left;
	width:31%;
	margin-right:3.5%;
	&:nth-of-type(3){
		margin-right:0;
	}
	.thumb{
		border-radius:45px;
		overflow:hidden;
		position:relative;
		img{
			display:block;
		}
		&:before{
			content: '';
		    position: absolute;
		    height: 100%;
		    width: 100%;
		    bottom: 0;
		    right: 0;
		    background: transparent;
		    border-radius:45px;
		    border: 10px solid rgba(255,255,255,0.7);
		}
	}
	h3{
		font-size:23px;
		margin:0;
		width:300px;
		max-width:100%;
		margin:30px auto 18px auto;
		line-height:1.25;
	}
	p{
		font-size:20px;
		line-height:1.25;
		margin:0 0 15px 0;
	}
	a{
		color:$green;
		display:block;
		font-size:20px;
		line-height: 1.17;
		font-weight:700;
	}
}

.view-all-news{
	color:$green;
	text-transform:uppercase;
	line-height: 1.39;
	font-size:18px;
	font-weight:700;
	display:block;
	margin-bottom:80px;
}