

a {
	text-decoration:none;
	color: $link-color;
}

a:hover {
	text-decoration:none;
	color: $link-hover-color;
}

a:active {
	text-decoration:none;
	color: $link-active-color;
}