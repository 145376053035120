
* {
	box-sizing: border-box;
	font-family: $main-font;
}

.flaticon {
	font-weight:400 !important;
}

.site{
	max-width:2000px;
	margin:0 auto;
	transition: transform 0.4s ease;
}

// To make something animated, it's best to add the animated class to the element in HTML instead of styling the elements individually in CSS

.animated {
	transition: $animation;
}

// To make something bordered, it's best to add the bordered class to the element in HTML instead of styling the elements individually in CSS

.bordered {
	border-radius: $border-radius;
}

// BUTTONS

.button {
	border-radius: $border-radius;
	padding: 15px 20px;
	display:block;
	background: $button-color;
	text-align:center;
	text-transform:uppercase;
	transition: $animation;
	color:#fff;
	cursor:pointer;
	outline:0;
}

.button .flaticon {
	display:inline-block;
	margin-right:10px;
}

.button:hover {
	background: $button-hover;
}

.button.green-button{
	background:$green;
}

.button.green-button:hover{
	background:darken($green, 10%);
}

.button.large-button{
	font-size:24px;
	font-weight:700;
	i{
		font-weight: 400;
	    padding-right: 12px;
	    font-size: 26px;
	}
}

// LAYOUTS

.wrapper {
	margin:0 auto;
	width: $wrapper-width;
	position:relative;
}

.sidebar {
	width: $sidebar-width;
}

.left-page {
	float:left;
	width:calc(100% - #{$sidebar-width});
}

.right-page {
	float:right;
	width:calc(100% - #{$sidebar-width});
}

.middle-page {
	float:none;
	margin:0 auto;
	width:calc(100% - #{$sidebar-width});
}

.left-sidebar {
	float:left
}

.right-sidebar {
	float:right;
	padding-left:20px;
}

.hide{
	display:none;
}

.mobile-menu {
	display: none;
}

.section-title{
	font-size:57px;
 	font-weight: 300;
  	line-height: 1.39;
  	letter-spacing: -2.9px;
  	text-align:center;
  	margin: 0;
  	color:#d0d0d0;
  	position:relative;
  	z-index: 2;
  	display:inline-block;
  	background: #fff;
    padding: 0 40px;
}

.sidebar-box{
	background:$light-grey;
	border-radius:5px;
	margin-bottom:60px;
	h3{
		margin:0;
		background:$main-color;
		color:#fff;
		text-align:center;
		font-weight:600;
		border-radius:5px 5px 0 0;
		padding:15px 0;
		font-size:18px;
	}
	.content{
		padding:25px;
	}
	p{
		font-size:18px;
		&:nth-of-type(1){
			margin-top:0;
		}
		&:last-of-type{
			margin-bottom:0;
		}
	}
	ul{
		margin:0 0 0 25px;
		padding:0;
		font-weight:600;
		font-size:17px;
		list-style-type:none;
		a{
			color:$font-color;
		}
		li{
			margin-bottom:10px;
			position:relative;
			&:before{
				content: '';
			    position: absolute;
			    height: 7px;
			    width: 7px;
			    background: #92bd3e;
			    -webkit-border-radius: 50%;
			    border-radius: 50%;
			    left: -20px;
			    top: 0;
			    bottom: 0;
			    margin: auto;
			    z-index: 2;
			}
		}
	}
}

.sidebar-newsletter{
	input{
		border-radius:$border-radius;
		padding:20px 25px;
		font-size:18px;
		width:100%;
		outline:0;
	}
	.button{
		display:block;
		margin:15px auto 0 auto;
		font-size:18px;
		font-weight:500;
		i{
			font-weight:normal;
			margin-right:5px;
		}
	}
}